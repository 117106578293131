///
/// If you make changes to this file you must also make changes to generic\src\app\shared\constants\globals.constant.ts
///
import { KeyboardCode } from '../models/keyboard-code.model';

export const ALLOWED_DECIMAL_KEYS = '0123456789.';
// export const PROCESS_INFO_MESSAGE = 'La información de tu expensa se está procesando...';
// export const BUILDING_EXPENSE_NOT_FOUND = 'La expensa ingresada no se encuentra disponible. Por favor comunicate con tu administrador para mayor información.';
// export const BUILDING_EXPENSE_NOT_TRIDI_ENABLED = 'Por el momento este servicio no se encuentra disponible. Por favor comunicate con tu administrador para mayor información.';
export const SERVICE_AVAILABLE = true;
export const MAX_AMOUNT: any = {value: 84375};
export const MAX_AMOUNT_PAYZEN: any = {value: 84375};
// export const MAX_AMOUNT_REPLACE = 'MAX_AMOUNT';
// export const PENDING_PAY = 'PENDING_PAY';
export const VIEW_MODE_FULL = 'full';
export const VIEW_MODE_EMBED = 'embed';
// export const URL_EXPENSAS_PAGAS = 'https://www.expensaspagas.com.ar/';
export const ALLOW_KEY_CODE = [
  KeyboardCode.backspace,
  KeyboardCode.enter,
  KeyboardCode.delete,
  KeyboardCode.tab,
  KeyboardCode.arrowLeft,
  KeyboardCode.arrowRight
];

export const RESTRICT_KEY_CODE = [
  KeyboardCode.bracketRight,
  KeyboardCode.bracketLeft,
  KeyboardCode.quote,
  KeyboardCode.backslash
];

export enum RoutePath {
  initPayment = 'init-payment',
  reviewPayment = 'review-payment',
  paymentMethod = 'payment-method',
  confirmPayment = 'confirm-payment',
  resultMessage = 'result-message',
  initAdminPayment = 'init-admin-payment',
  discountCoupon = 'discount-coupon',
  cvuInitPayment = 'init-payment/cvu'
}
