///
/// If you make changes to this file you must also make changes to src\app\shared\constants\globals.constant.ts
///
export const PROCESS_INFO_MESSAGE = 'Tu información se está procesando...';
export const HELP_PHONE = '0810-362-2256';
export const HELP_EMAIL = 'procesos@plaso.online';
export const BUILDING_EXPENSE_NOT_FOUND = 'El código ingresado no se encuentra disponible. Por favor comunicate con nuestro Centro de Atención y Consultas al <b>'+HELP_PHONE+'</b> para mayor información.';
export const BUILDING_EXPENSE_NOT_TRIDI_ENABLED = 'Por el momento este servicio no se encuentra disponible. Por favor comunicate con tu administrador para mayor información.';
export const URL_EXPENSAS_PAGAS = 'https://www.plaso.com.ar/';
export const SITE_NAME = 'plaso.com.ar';

export const MAX_AMOUNT_REPLACE = 'MAX_AMOUNT';
export const PENDING_PAY = 'PENDING_PAY';