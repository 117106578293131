import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { WizardParam, WizardStep } from "./wizard.model";
import { timer } from "rxjs";
import { PaymentTransactionService } from "../shared/services/payment-transaction/payment-transaction.service";

@Injectable()
export class WizardService {
  private currentStepObserver = new Subject<object>();
  private sendParamsData = new Subject<object>();
  private reloadSteps = new Subject<number>();
  private editWizardSteps = new Subject<string>();
  public currentStep: number;
  public wizardParams: WizardParam;
  public wizardStep: WizardStep;
  public updateCurrentStep = this.currentStepObserver.asObservable();
  public paramsInformation = this.sendParamsData.asObservable();
  public reloadNavigation = this.reloadSteps.asObservable();
  public changeWizardSteps = this.editWizardSteps.asObservable();

  private expirationDate;
  private deletecouponStatus = new BehaviorSubject<boolean>(false);

  private timeoutId: any;

  constructor(private paymentTransactionService: PaymentTransactionService) {}

  public setExpirationDate(date: any) {
    if (date != null) {
      this.expirationDate = date;
    }

    if (this.expirationDate) {
      const expirationDate = new Date(this.expirationDate);
      const currentDate = new Date();

      const miliseconds = expirationDate.getTime() - currentDate.getTime();

      this.timeoutId = setTimeout(() => {
        const transaction =
          this.paymentTransactionService.getCacheTransaction();
        if (transaction) {
          this.paymentTransactionService.DeleteCoupon(transaction.id).subscribe(
            () => {
              this.deletecouponStatus.next(true);
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }, miliseconds);
    } else {
      this.unsuscribeExpirationDate();
    }
  }

  public unsuscribeExpirationDate() {
    clearTimeout(this.timeoutId);
  }

  getDeletecouponStatus() {
    return this.deletecouponStatus.asObservable();
  }

  public sendParametersBuildWizard(urlParams: object, transactionId: string) {
    this.sendParamsData.next({ urlParams, transactionId });
  }

  public reloadWizard(step: number) {
    this.reloadSteps.next(step);
  }

  public setInitialStep(step: WizardStep, wizard) {
    this.currentStep = (step && step.stepNumber) || 0;
    this.wizardStep = wizard;
  }

  public nextStep(transactionId: string): void {
    this.currentStep = this.currentStep + 1;
    this.updateStep(transactionId);
  }

  public previousStep(transactionId: string): void {
    this.currentStep = this.currentStep - 1;
    this.updateStep(transactionId);
  }

  public resetStep(transactionId: string): void {
    this.currentStep = 0;
    this.updateStep(transactionId);
  }

  private updateStep(transactionId): void {
    const newStep = { step: this.currentStep, transaction: transactionId };
    this.currentStepObserver.next(newStep);
  }

  public removeStep(path: string) {
    this.editWizardSteps.next(path);
  }
}
