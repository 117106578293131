import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PaymentMethod } from "../../constants/payments-methods.constants";
import { HttpService } from "../http/service";

@Injectable()
export class PayzenService {
  public paymentMethod = PaymentMethod;

  constructor(private httpService: HttpService) {}

  public getPayzenEnvironment(
    transactionPaymentMethod: number
  ): Observable<any> {
    if (transactionPaymentMethod == this.paymentMethod.payzenDebit) {
      return this.httpService.get("get-payzen-env-debito").pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
    }
    if (
      transactionPaymentMethod == this.paymentMethod.payzenCreditInstallments
    ) {
      return this.httpService.get("get-payzen-env-credito-installments").pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
    }
    return this.httpService.get("get-payzen-env-credito").pipe(
      map((response: any) => {
        return response;
      }),
      catchError((e) => throwError(e))
    );
  }

  public saveNameAndDocumentNumber(transactionId, data): Observable<any> {
    return this.httpService
      .edit("save-name-and-document-number", data, transactionId)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }
}
