export const KeyboardCode = {
  backspace: 'Backspace',
  enter: 'Enter',
  delete: 'Delete',
  point:  '.',
  arrowLeft: 'ArrowLeft',
  arrowRight: 'ArrowRight',
  tab: 'Tab',
  bracketRight: 'BracketRight',
  bracketLeft: 'BracketLeft',
  quote: 'Quote',
  backslash: 'Backslash',
  home: 'Home',
  end: 'End'
};

