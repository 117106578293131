import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlockScreenService {

  private stateBlockScreen = false;
  private blockScreenSubject = new Subject<boolean>();
  public blockScreen = this.blockScreenSubject as Observable<boolean>;

  constructor() {
  }

  toogleBlockScreen() {
    this.stateBlockScreen = !this.stateBlockScreen;
    this.blockScreenSubject.next(this.stateBlockScreen);
  }

  lockScreen() {
    this.stateBlockScreen = true;
    this.blockScreenSubject.next(this.stateBlockScreen);
  }

  unlockScreen() {
    this.stateBlockScreen = false;
    this.blockScreenSubject.next(this.stateBlockScreen);
  }

  getStateBlockScreen() {
    return this.stateBlockScreen;
  }

}
