import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  imports: [
    DpDatePickerModule,
    CommonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [ DatePickerComponent ],
  exports: [ DatePickerComponent ],
  providers: [ { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }]
})
export class DatePickerModule { }
