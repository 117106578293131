import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardComponent } from './wizard.component';
import { InitPaymentService } from '../shared/services/init-payment/init-payment.service';
import { SharedModule } from '../shared/shared.module';
import { InformationModalComponent } from '../shared/components/information-modal/information-modal.component';
import { InformationModalModule } from '../shared/components/information-modal/information-modal.module';
import { ConfirmationModalModule } from '../shared/components/confirmation-modal/confirmation-modal.module';

@NgModule({
  imports: [
    CommonModule,
    InformationModalModule,
    ConfirmationModalModule,
  ],
  declarations: [
    WizardComponent
  ],
  providers: [
    InitPaymentService
  ],
  exports: [
    WizardComponent
  ]
})

export class WizardModule { }
