import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

const ONLY_NUMBERS_REGEXP = '^[0-9]*$';

@Directive({
 selector: '[appInputFilterCharacters]'
})

export class InputFilterCharactersDirective {

   @Input() inputPattern: string;
   @Output() inputChanged = new EventEmitter();

   private currentValue: string;
   private currentCaretPos: number;

   constructor(private el: ElementRef) {}

   @HostListener('keydown', [ '$event' ])
   onkeydown() {
      this.currentCaretPos = this.el.nativeElement.selectionStart;
   }

   @HostListener('input', [ '$event' ])
   oninput(event) {
      const VALIDATION_REGEX = new RegExp(!this.inputPattern ? ONLY_NUMBERS_REGEXP : `^${this.inputPattern}$`);
      const newText = event.target.value;

      if (this.el.nativeElement.maxLength > 0 && newText.length > this.el.nativeElement.maxLength) {
         event.target.value = this.currentValue;
         this.el.nativeElement.selectionStart = this.currentCaretPos;
         this.el.nativeElement.selectionEnd = this.currentCaretPos;
      }

      if (!VALIDATION_REGEX.test(newText) && newText !== '') {
         event.target.value = this.currentValue;
         this.el.nativeElement.selectionStart = this.currentCaretPos;
         this.el.nativeElement.selectionEnd = this.currentCaretPos;
      }

      this.currentValue = event.target.value;
      this.currentCaretPos = this.el.nativeElement.selectionStart;

      this.inputChanged.emit(this.currentValue);
   }

   @HostListener('focus', [ '$event' ])
   onfocus(event) {
      this.currentValue = event.target.value;
      this.currentCaretPos = this.el.nativeElement.selectionStart;
   }
}
