import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-data-privacy-policy',
  templateUrl: './data-privacy-policy.component.html',
  styleUrls: ['./data-privacy-policy.component.scss']
})
export class DataPrivacyPolicyComponent {
  @Input() open: boolean;
  @Output() closeEvent = new EventEmitter();

  public close(): void {
    this.closeEvent.emit();
  }
}
