import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  maximumDate: Date;
  minimumDate: Date;
  dateNow: Date;

  @Input() name: string = "default";
  @Input() formGroupScope: FormGroup = new FormGroup({
    default: new FormControl(),
  });
  @Input() ngForm: NgForm;
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;

  @Output() modelChange = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.maximumDate = moment(this.maxDate).toDate();
    this.minimumDate = moment(this.minDate).toDate();
    this.dateNow = moment().toDate();
  }

  changeEvent(event: any) {
    this.modelChange.emit(moment(event.date).format("DD/MM/YYYY"));
  }
}
