<div class="c-modal" id="help-modal" [ngClass]="{'c-modal--is-visible': open}">
  <div class="c-modal__content">
      <a (click)="close()"  class="c-modal__close"></a>

      <header class="header__block" [ngClass]="{'header__block-modify': changeStyle}">
      <h2>{{content?.title}}</h2>
      </header>

      <div class="c-modal__wrapper">
        <img *ngIf="content?.img" src="../assets/img/{{content?.img}}" alt="">

        <div class="help__block">
          <h3 *ngIf="!changeStyle">{{content?.subtitle}}</h3>
          <h4 *ngIf="changeStyle" [ngClass]="{'help__block-modify': content?.body}"> {{content?.subtitle}}</h4>

          <p [innerHTML]="content?.body"></p>
          <p *ngIf="content?.footer"><strong [innerHTML]="content?.footer"></strong></p>
        </div>
      </div>
      <div class="footer__block">
        <button class="button button--primary" (click)="accept()" *ngIf="showAcceptButton">{{textAcceptButton}}</button>
        <button class="button button--secondary" (click)="cancel()" *ngIf="showCancelButton">{{textCancelButton}}</button>
      </div>
  </div>
</div>
