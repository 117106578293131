<div class="c-modal c-modal--text" id="terms-and-conditions" [ngClass]="{'c-modal--is-visible': open}">
  <div class="c-modal__content">
    <a (click)="close()" class="c-modal__close"></a>

    <header class="header__block">
      <h2>Términos y Condiciones</h2>
    </header>

    <div class="c-modal__wrapper" [innerHTML]="termsConditions">
     </div>
  </div>
</div>
