import { Directive, HostListener, ElementRef } from '@angular/core';
import { KeyboardCode } from '../../models/keyboard-code.model';

@Directive({
  selector: '[appExpDate]'
})

export class ExpirationDateDirective {
  el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('keyup', ['$event'])

  onkeyup(event: KeyboardEvent) {
    const parts = this.el.value.split('/');

    if (event.key === KeyboardCode.backspace && this.el.value.charAt(this.el.value.length - 1) === '/') {
      this.el.value = parts[0];
      return;
    }

    if (parts.length === 1) {
      if (parts[0].length === 2)
        this.el.value = this.el.value.concat('/');

      if (parts[0].length > 2)
        this.el.value = parts[0].substr(0, 2) + '/' + parts[0].substr(2);

      return;
    }

    let month = parseInt(parts[0], 10).toString();
    month = month.length === 1 ? '0' + month : month;
    this.el.value = month + '/' + parts[1];
  }
}

