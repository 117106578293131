import { Component, Input, OnInit } from '@angular/core';
import { PROCESS_INFO_MESSAGE } from '../../constants/shared.constant';

@Component({
  selector: 'app-process-info-message',
  templateUrl: './process-info-message.component.html',
  styleUrls: ['./process-info-message.component.scss']
})
export class ProcessInfoMessageComponent implements OnInit {
  public enableLoader = false;

  @Input() processing : boolean = false;
  
  constructor() {}

  message:string=PROCESS_INFO_MESSAGE;

  ngOnInit() {
  }

}
