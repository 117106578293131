import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  public enableLoader = false;

  constructor(
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
   this.loaderService.loaderStatus.subscribe((status: boolean) => {
      this.enableLoader = status;
    });
  }

}
