<div class="c-modal c-modal--text" id="data-privacy-policy" [ngClass]="{'c-modal--is-visible': open}">
    <div class="c-modal__content">
      <a (click)="close()" class="c-modal__close"></a>
  
      <header class="header__block">
        <h2>ANEXO V</h2>
      </header>
  
      <div class="c-modal__wrapper">
        <p><strong>CONVENIO DE PROTECCIÓN Y CONFIDENCIALIDAD DE DATOS</strong></p>
        <p><span >Quien suscribe en carácter de Administrador/a Acepta el presente Convenio de
          Protección y Confidencialidad de direcciones Electrónicas para vincular a Plataforma
          de Pagos S.A., quien constituye domicilio según artículo 15 de Propuesta de
          Prestación de servicios, en adelante denominada “Plataforma”, con el Sistema de
          gestión y/o liquidación de expensas a tal fin utilizado en adelante “Sistema”. Esta
          aceptación forma parte de la propuesta de prestación de servicios, quedando sujeta a
          las siguientes cláusulas:
           </span></p>
        <strong>PRIMERA:</strong>
        <p><span>Administrador/a presta conformidad mediante la presente aceptación para
          que Plataforma solicite a Sistema las direcciones de emails de las unidades funcionales
          asociadas a los consorcios adheridos a Plataforma, con la exclusiva finalidad de cursar
          invitaciones electrónicas de pago de expensa, avisos de vencimientos, notificaciones
          sobre nuevos medios de pagos y/o nuevos medios disponibles en el Portal de Pagos.
          </span></p>
        <strong>SEGUNDA:</strong>
        <p><span >Plataforma se compromete a mantener en restricta reserva y
          confidencialidad las direcciones de emails informadas por Administrador/a a través del
          Sistema, no pudiendo revelar, publicar, utilizar, disponer, dar a conocer, ceder, destinar a
          otros fines que no sean las mencionadas en cláusula primera.
          </span></p>
        <strong>TERCERA:</strong>
        <p><span>Plataforma libera de toda responsabilidad frente a terceros a Administradora y a
          Sistema por la protección y confidencialidad de los emails, conforme la tecnología lo
          requiere para brindar un servicio de pago optativo para los consorcistas que quieran
          voluntariamente al Portal de Pagos.</span></p>
        <strong>CUARTA:</strong>
        <p><span>Plataforma asume el carácter de responsable habiendo cumplimentado con
          los requisitos de seguridad exigidos por Ley 25.326, regulada por la Dirección Nacional de
          protección de Datos Personales.</span></p>
        <strong>QUINTA:</strong>
        <p><span>Administradora mantendrá actualizados los emails de las unidades
          funcionales en Sistema conforme los consorcios que incorpore en el Anexo II - DATOS
          DEL ADMINISTRADOR Y CONSORCIO que la vincula con Plataforma.
          </span></p>
        <strong>SEXTA:</strong>
        <p><span>El presente Convenio de Protección y Confidencialidad de Direcciones
          Electrónicas, es de carácter gratuito entre Administrador/a, Sistema y Plataforma, y
          tiene como objeto brindar servicios basados en sistemas, integrados en beneficio
          común de los Consorcistas.
          </span></p>
        <strong>SEPTIMA:</strong>
        <p><span>La presente aceptación implica la posterior suscripción por parte de
          Administrador/a a la documentación correspondiente, que deberá presentarse para el alta
          definitiva al Portal de Pagos.</span></p>
      </div>
    </div>
  </div>
  