import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageHandlerComponent } from './message-handler.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MessageHandlerComponent],
  exports: [MessageHandlerComponent]
})
export class MessageHandlerModule { }
