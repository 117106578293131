export class ExpensesDetail {
  id: string;
  dueDate1: string;
  amount1: number;
  dueDate2: string;
  amount2: number;
  address: string;
  buildingManagerName: string;
  location: string;
  functionalUnitCode: number;
  ownerName: string;
  fullOwnerName: string;
  electronicPaymentCode: string;
  buildingExpensePaymentStatus: number;
  ownersAssociationName: string;
  period: string;
  paidAmount: number;
  lastPaymentDate: string;
  registeredPaymentsCount: number;
  itemCode?: any;
  isTridiAvailable: boolean;
  isEmailSubscribed: boolean;

  constructor() {
    this.id = '';
    this.dueDate1 = '';
    this.amount1 = null;
    this.dueDate2 = '';
    this.amount2 = null;
    this.address = '';
    this.buildingManagerName = '';
    this.location = '';
    this.functionalUnitCode = null;
    this.ownerName = '';
    this.fullOwnerName = '';
    this.electronicPaymentCode = '';
    this.buildingExpensePaymentStatus = null;
    this.ownersAssociationName = '';
    this.period = '';
    this.paidAmount = null;
    this.lastPaymentDate = '';
    this.registeredPaymentsCount = null;
    this.itemCode = null;
    this.isTridiAvailable = null;
    this.isEmailSubscribed = null;
  }
}
