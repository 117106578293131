export const WORLDCUP_TERMS_CONDITIONS = `<p><strong>BASES Y CONDICIONES DE LA PROMOCI&Oacute;N</strong></p>
<p><h2>&ldquo;¡EXP al Mundial!&rdquo;</h2></p>
<strong>1. Organizador, Alcance y Vigencia.</strong>
<p><span>1.1. Plataforma de Pagos S.A con domicilio en Demaria 4683 Piso 7&deg;A  de la Ciudad Aut&oacute;noma de Buenos Aires, (en adelante denominado indistintamente, &ldquo;PLAPSA&rdquo;  y/o el &ldquo;Organizador&rdquo;) organiza esta promoci&oacute;n denominada &ldquo;¡EXP al Mundial!&rdquo;  (en adelante referida como la &ldquo;Promoci&oacute;n&rdquo;) y est&aacute; dirigida a todas las personas mayores de 18 a&ntilde;os, que tengan domicilio en la Rep&uacute;blica Argentina, y que cumplan con los requisitos de participaci&oacute;n establecidos en estas Bases y Condiciones (en adelante denominadas, las &ldquo;Bases&rdquo;).</span></p>
<p><span>1.2. La Promoci&oacute;n tendr&aacute; vigencia a partir del d&iacute;a 20 de noviembre de 2022 a las 00:01 horas hasta el 10 de diciembre de 2022 a las 23:59 horas (en adelante, el plazo indicado ser&aacute; denominado, el &ldquo;Plazo de Vigencia&rdquo;) y es sin obligaci&oacute;n de compra.</span></p>
<strong>2. Requisitos de Participaci&oacute;n.</strong>
<p><span>2.1. Podr&aacute;n participar en la Promoci&oacute;n  todas las personas f&iacute;sicas, mayores de edad, que residan en la Rep&uacute;blica Argentina, (en adelante denominados, los &ldquo;Participantes&rdquo;).</span></p>
<p><span>2.2. No podr&aacute; participar de la Promoci&oacute;n  el personal del Organizador, sus parientes hasta el segundo grado de consanguinidad y segundo de afinidad, ni los exempleados que se hubieran desvinculado del Organizador dentro de los 30 d&iacute;as anteriores a la fecha de comienzo de la Promoci&oacute;n, ni sus parientes hasta el segundo grado de consanguinidad, inclusive.</span></p>
<strong>3. Forma de Participaci&oacute;n.</strong>
<p><span>3.1. La Promoci&oacute;n se dar&aacute; a conocer mediante publicidad digital en redes sociales y mail. Las personas interesadas en participar podr&aacute;n hacerlo de la siguiente forma:</span></p>
<p><span><strong>Participaci&oacute;n autom&aacute;tica:</strong> Los Participantes que durante el Plazo de Vigencia paguen sus expensas con tarjeta de d&eacute;bito por el Portal de Pagos ofrecido por Plataforma de Pagos S.A participar&aacute;n de la Promoci&oacute;n.</span></p>
<p><span>3.2. Ser&aacute; eliminado de la Promoci&oacute;n quien hubiese realizado desdoblamientos en el monto de una expensa al momento del pago con el fin de obtener m&aacute;s chances.</span></p>
<strong>4. Premios</strong>
<p><span>4.1. Los premios consistir&aacute;n en la entrega de 4 camisetas de la Selecci&oacute;n Argentina de F&uacute;tbol Original de Adidas, 48 llaveros, 48 lapiceras, 24 cuadernos anotadores, 24 botellas, 24 jarros t&eacute;rmicos y 24 mouse pads que ser&aacute;n entregados a los Ganadores del Sorteo hasta agotar stock.</span></p>
<p><span>4.2. Los Premios se limitan al detalle mencionado y hasta agotar stock, y por lo tanto no incluye otros bienes, prestaciones ni gastos tales como traslado de los Participantes para retirar y/o utilizar el Premio en caso de corresponder; tampoco se incluyen los gastos que los Participantes realicen en ocasi&oacute;n de la utilizaci&oacute;n y/o retiro del Premio, como as&iacute; tampoco los descuentos, tasas o impuestos que pudiere corresponder. El Premio tampoco incluye coberturas por p&eacute;rdida, robo y/o da&ntilde;os parciales respecto del Premio, ni cualquier otro servicio que no est&eacute; expresamente detallado espec&iacute;ficamente en las presentes Bases. En consecuencia, todo gasto adicional en el que incurra el Participante para acceder y hacer uso del Premio correr&aacute; por exclusiva cuenta del Participante. El Premio es intransferible.</span></p>
<strong>5. Suspensi&oacute;n o Cancelaci&oacute;n de la Promoci&oacute;n.</strong>
<p><span>5.1. Cuando circunstancias no imputables al Organizador ni previstas en estas Bases o que constituyan caso fortuito o fuerza mayor lo justifiquen, el Organizador podr&aacute; suspender, cancelar o modificar esta Promoci&oacute;n.</span></p>
<strong>6. Difusi&oacute;n del nombre de los Ganadores</strong>
<p><span>6.1 Los ganadores autorizan al Organizador a utilizar sus voces, im&aacute;genes y datos personales, con fines comerciales, en los medios y formas que PLAPSA disponga, sin derecho a compensaci&oacute;n alguna, hasta transcurrido un (1) a&ntilde;o de finalizada la Promoci&oacute;n.</span></p>
<p><span>Los datos personales brindados por los Participantes de la Promoci&oacute;n ser&aacute;n incorporados a la base de datos de titularidad de PLAPSA para contactarlos por ofertas, promociones, concursos, recabar opiniones, realizar estad&iacute;sticas, etc. La provisi&oacute;n de los datos por parte de los Participantes es voluntaria, pero necesaria para poder participar de la Promoci&oacute;n. Los Participantes podr&aacute;n requerir que dicha informaci&oacute;n le sea suministrada, actualizada, rectificada o removida de la base de datos a su pedido. A tales efectos deber&aacute;n contactarse telef&oacute;nicamente al 0810-362-2256.</span></p>
<strong>7. Asignaci&oacute;n del Premio y fechas de entrega.</strong>
<p><span>7.1. La selecci&oacute;n entre todos los Participantes que hayan cumplido con los requisitos establecidos en las presentes Bases se llevar&aacute; a cabo el 12 de diciembre de 2022, donde se realizar&aacute; el sorteo por el cual se obtendr&aacute; a los potenciales ganadores. Si transcurridas 48 horas h&aacute;biles no fuera posible el contacto con los potenciales ganadores, el Organizador podr&aacute; reemplazarlo por otros potenciales Ganadores obtenido en el mismo acto del sorteo.</span></p>
<p><span>7.2. Luego de lograr el contacto v&iacute;a mail con los Ganadores se coordinar&aacute; la entrega del Premio, la cual se realizar&aacute; por correo privado al domicilio que los Ganadores le indiquen a PLAPSA.</span></p>
<p><span>7.3 El resultado del sorteo ser&aacute; anunciado a cada uno de los participantes que hayan resultado sorteados a partir del 12 de diciembre de 2022 a trav&eacute;s del correo electr&oacute;nico proporcionado por el participante al momento de suscribir su participaci&oacute;n. Los Ganadores deber&aacute;n responder, de manera individual, al correo electr&oacute;nico enviado por el Organizador, para poder acceder al premio. En caso de silencio por parte de los participantes que resulten potenciales ganadores y transcurrido 48 horas h&aacute;biles desde el contacto con los mismos, se entender&aacute; que los participantes en cuesti&oacute;n renuncian irrevocablemente al premio sin derecho a efectuar ning&uacute;n tipo de reclamo. Se considerar&aacute; que los participantes han prestado su consentimiento al respecto por el solo hecho de participar en el sorteo.</span></p>
<strong>8. Probabilidades</strong>
<p><span>8.1. La probabilidad de que un participante de la Promoci&oacute;n resulte potencial ganador de uno de los premios depender&aacute; de la cantidad de Participantes y de la cantidad de chances que cada uno sume. En consecuencia, suponiendo que en la Promoci&oacute;n participaran 1.000 personas, con una sola chance cada uno, la probabilidad de hacerse acreedor del premio ser&aacute; de 1/1000.</span></p>
<strong>9. Impuestos y Gastos</strong>
<p><span>9.1 Estar&aacute;n exclusivamente a cargo de los Participantes todo impuesto, tasa y/o contribuci&oacute;n que deba tributarse sobre o en relaci&oacute;n con su participaci&oacute;n en la Promoci&oacute;n como en la obtenci&oacute;n de los premios y los gastos en que incurran tanto para participar de la Promoci&oacute;n como cuando concurran a reclamar y/o retirar y/o utilizar los premios.</span></p>
<strong>10. Aceptaci&oacute;n de las Bases.</strong>
<p><span>10.1. La participaci&oacute;n en la Promoci&oacute;n implica la aceptaci&oacute;n de estas Bases, las que por causas ajenas y de fuerza mayor no imputables al Organizador, podr&aacute;n ser modificadas, sin que ello d&eacute; lugar a reclamo o indemnizaci&oacute;n alguna.</span></p>
<strong>11. Difusi&oacute;n</strong>
<p><span>11.1. Esta promoci&oacute;n ser&aacute; difundida a trav&eacute;s de redes sociales y mail.</span></p>
<strong>12. Responsabilidad</strong>
<p><span>12.1. El Organizador no ser&aacute; responsable por los da&ntilde;os y perjuicios que pudiera sufrir el potencial ganador o terceros, en sus personas o bienes, con motivo o en ocasi&oacute;n de la participaci&oacute;n en la Promoci&oacute;n y/o con motivo o en ocasi&oacute;n del retiro y/o canje del Premio y/o utilizaci&oacute;n de &eacute;ste.</span></p>
<strong>13. Sin obligaci&oacute;n de Compra.</strong>
<p><span>13.1. Para participar de esta Promoci&oacute;n no existe obligaci&oacute;n de compra. En consecuencia, los interesados no necesitan comprar ning&uacute;n producto o contratar ning&uacute;n servicio oneroso para participar y ganar en esta Promoci&oacute;n.</span></p>
<strong>14. Solicitud de Bases:</strong>
<p><span>14.1. Estas Bases podr&aacute;n ser consultadas en forma gratuita en: a) el siguiente n&uacute;mero telef&oacute;nico 0810-362-2256 o b) v&iacute;a mail a procesos@expensaspagas.com.ar. </span></p>
`
