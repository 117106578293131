import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {SERVICE_AVAILABLE} from '../constants/globals.constant';

@Injectable()
export class UnavailableService implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(): boolean {
    if (SERVICE_AVAILABLE) {
      this.router.navigate(['/']);
    }
    return !SERVICE_AVAILABLE;
  }

}
