import { Injectable } from '@angular/core';
import { Subject ,  Observable, merge, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LoaderService {
  public loader = new Subject<boolean>();
  public loaderStatus = this.loader.asObservable();
  public connection = this.getConnection();

  public showLoader(): void {
    this.loader.next(true);
  }

  public hideLoader(): void {
    this.loader.next(false);
  }

  /**
   * Returns true or false if there is a lan connection or not respectively
   */
  private getConnection(): Observable<boolean> {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable<boolean>(sub => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
