<div class="wizard">
  <div class="wrapper">
    <div *ngFor="let step of wizardSteps | slice:0:wizardSteps.length-1"
         [ngClass]="{'wizard__step--is-active': step.state == 'active', 'wizard__step--is-completed': step.state == 'completed'}"
         class="wizard__step">
      {{step.name}}
    </div>
  </div>
</div>

<app-confirmation-modal 
  [open]="displayDeleteCoupon"
  description="¡El cupón de descuento expiró!" question=""
  (closeEvent)="closeModalDeleteCoupon()" 
  (confirmEvent)="closeModalDeleteCoupon()"
  [closeButtomEnabled]="false"></app-confirmation-modal>