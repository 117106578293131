import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { endpoints } from "./config";
import { catchError, map } from "rxjs/operators";
// import {ApplicationInsightsService} from '../application-insights/application-insights.service';
// import {ApplicationInsightsErrorHandlerService} from '../application-insights-error-handler/application-insights-error-handler.service';

@Injectable()
export class HttpService {
  private headers = new HttpHeaders({ "Content-Type": "application/json" });
  private options = { headers: this.headers };

  constructor(private httpClient: HttpClient) {}

  public getBlob(name: string, params?: any, transactionId?): Observable<any> {
    let uri;
    let headers = new HttpHeaders();

    if (params) {
      uri = this.getUrlRequest(
        environment.baseApiUrl + endpoints[name],
        params
      );
    } else {
      if (transactionId) {
        uri =
          environment.baseApiUrl +
          endpoints[name].replace(":transactionId", transactionId);
      } else {
        uri = environment.baseApiUrl + endpoints[name];
      }
    }

    return this.httpClient
      .get(uri, {
        observe: "response",
        headers,
        responseType: "blob" as "json",
      })
      .pipe(
        map((response) => response),
        catchError(this.handleError)
      );
  }

  public get(name: string, params?: any, transactionId?): Observable<any> {
    let uri;

    if (transactionId) {
      uri =
        environment.baseApiUrl +
        endpoints[name].replace(":transactionId", transactionId);
    } else {
      uri = environment.baseApiUrl + endpoints[name];
    }
    // }
    if (params) {
      // uri = this.getUrlRequest(environment.baseApiUrl + endpoints[name], params);
      uri = this.getUrlRequest(uri, params);
    }

    // if (params) {
    //   uri = this.getUrlRequest(environment.baseApiUrl, params);
    // } else {
    //   uri = environment.baseApiUrl + serviceName;
    // }

    return this.httpClient.get(uri).pipe(catchError(this.handleError));
  }

  public getWithQueryParam(name: string, params: any): Observable<any> {
    const queryParams = new HttpParams().set("electronicPaymentCode", params);

    return this.httpClient
      .get(environment.baseApiUrl + endpoints[name], { params: queryParams })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  public create(
    name: string,
    data: any,
    transactionId?: string
  ): Observable<any> {
    const body = JSON.stringify(data);
    let uri = environment.baseApiUrl + endpoints[name];
    if (transactionId) {
      uri =
        environment.baseApiUrl +
        endpoints[name].replace(":transactionId", transactionId);
    }

    return this.httpClient
      .post(uri, body, this.options)
      .pipe(catchError(this.handleError));
  }

  public patch(
    name: string,
    data: any,
    transactionId: string
  ): Observable<any> {
    const body = JSON.stringify(data);
    const uri =
      environment.baseApiUrl +
      endpoints[name].replace(":transactionId", transactionId);

    return this.httpClient
      .patch(uri, body, this.options)
      .pipe(catchError(this.handleError));
  }

  public edit(name: string, data: any, transactionId: string): Observable<any> {
    const body = JSON.stringify(data);
    const uri =
      environment.baseApiUrl +
      endpoints[name].replace(":transactionId", transactionId);

    return this.httpClient
      .put(uri, body, this.options)
      .pipe(catchError(this.handleError));
  }

  public delete(name: string, transactionId: string, paymentId: string) {
    const SERVICE_NAME = endpoints[name].replace(":id", paymentId);
    const uri =
      environment.baseApiUrl +
      SERVICE_NAME.replace(":transactionId", transactionId);

    return this.httpClient
      .delete(uri, this.options)
      .pipe(catchError(this.handleError));
  }

  simpleUrlRequest(method, url) {
    return this.httpClient.request(method, url);
  }

  private getUrlRequest(url, params: any) {
    // return encodeURI(url + `/${params}`);
    let urlArguments = "";
    let cont = 0;
    let arrayElement = "";

    if (!url) return false;

    if (params !== undefined) {
      if (typeof params === "object") {
        for (const key in params) {
          if (params[key]) {
            arrayElement =
              typeof params[key] === "string" ? params[key].split("&") : [];
            if (Array.isArray(params[key]) && arrayElement.length == 0)
              arrayElement = params[key];
            cont++;
            if (cont > 1) {
              urlArguments += "&";
            }
            if (arrayElement.length > 1) {
              for (let i = 0; i < arrayElement.length; i++) {
                if (i > 0) urlArguments += "&";
                urlArguments += `${key}=${arrayElement[i]}`;
              }
            } else {
              urlArguments += `${key}=${params[key]}`;
            }
          }
        }

        if (urlArguments) urlArguments = `?${urlArguments}`;
      } else {
        // if params not an object in that case is a single parameter
        urlArguments = `/${params}`;
      }
    }
    return encodeURI(url + urlArguments);
  }

  protected replaceUrlParams(url, params) {
    if (params !== undefined) {
      if (typeof params === "object") {
        try {
          return url
            .split("/")
            .map((segment) => {
              if (!segment.includes(":")) {
                return segment;
              }
              const paramKey = segment.split(":")[1];
              if (params[paramKey]) return params[paramKey];
              throw new Error(
                "Param requerido: la url define el parametro " +
                  segment +
                  " que no se recibio en el array de parametros."
              );
            })
            .join("/");
        } catch (e) {
          return e;
        }
      } else {
        return url.replace(":id", params);
      }
    }
    return encodeURI(url);
  }

  private handleError(error: any) {
    return throwError(error);
  }
}
