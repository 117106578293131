import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalConfig } from '../../models/modal.model';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})

export class InformationModalComponent {
  @Input() open: boolean;
  @Input() content = new ModalConfig();
  @Input() showAcceptButton: boolean = false;
  @Input() showCancelButton: boolean = false;
  @Input() changeStyle: boolean = false;
  @Output() closeEvent = new EventEmitter();
  @Output() acceptEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Input() textAcceptButton: string = 'Aceptar';
  @Input() textCancelButton: string = 'Cancelar';

  public close(): void {
    this.closeEvent.emit({close: !open, code: this.content.code});
  }

  public accept(): void {
    this.acceptEvent.emit({close: !open, code: this.content.code});
  }

  public cancel(): void {
    this.cancelEvent.emit({close: !open, code: this.content.code});
  }
}
