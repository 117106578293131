import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {ApplicationInsightsService} from '../application-insights/application-insights.service';

@Injectable()
export class ApplicationInsightsErrorHandlerService extends ErrorHandler {

  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any): void {
    this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).logException(error);
    console.log(error);
  }
}
