import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { PORGRAM_REFERRED_TERMS_CONDITIONS } from "../../constants/program-referred-terms-and-conditions.constant";
import { TERMS_CONDITIONS } from "../../constants/terms-and-conditions.constant";
import { WORLDCUP_TERMS_CONDITIONS } from "../../constants/worldcup-terms-and-conditions.constant";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"],
})
export class TermsAndConditionsComponent implements OnInit {
  @Input() open: boolean;
  @Input() type: string;
  @Output() closeEvent = new EventEmitter();

  termsConditions: string;

  public ngOnInit(): void {
    switch (this.type) {
      case "referred-program":
        this.termsConditions = PORGRAM_REFERRED_TERMS_CONDITIONS;
        break;
      case "world-cup":
        this.termsConditions = WORLDCUP_TERMS_CONDITIONS;
      case "default":
        this.termsConditions = TERMS_CONDITIONS;
      default:
        this.termsConditions = TERMS_CONDITIONS;
        break;
    }
  }

  public close(): void {
    this.closeEvent.emit();
  }
}
