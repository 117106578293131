import {Component, OnInit, Inject} from '@angular/core';
import {LoaderService} from './shared/services/loader/loader.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: any,
    private loaderService: LoaderService,
  //  private applicationInsightsService: ApplicationInsightsService
  ) {
  }

  ngOnInit() {
    // TODO: Delete all between this...
    // this.applicationInsightsService.logTrace('Probando el "LogTrace" 3');
    // this.applicationInsightsService.logEvent('Se inicio el unsubscribe "LogEvent" 3');
    // this.applicationInsightsService.logMetric('Metrica desde unsubscribe "LogMetric" 3', 4);
    // this.applicationInsightsService.logPageView('Log de pagina "LogPageView" 3');
    // this.applicationInsightsService.logException(new Error('El error para oscar'), 4);
    // TODO: and this.
    this.loaderService.connection
      .subscribe((online: boolean) => {
        if (online) {
          this.document.body.classList.remove('app--connection-lost');
        } else {
          this.document.body.classList.add('app--connection-lost');
        }
      });
  }
}
