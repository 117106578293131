export const PORGRAM_REFERRED_TERMS_CONDITIONS = `<p><strong>T&Eacute;RMINOS Y CONDICIONES PROGRAMA DE REFERIDOS &ldquo;INVITA A TUS VECINOS&rdquo;:</strong></p>
<ol>
    <p><li>Los cupones podr&aacute;n utilizarse &uacute;nicamente en la web <a href="//www.expensaspagas.online" target="_blank">expensaspagas.online</a>.</li></p>
    <p><li>Los cupones que se regalen en este programa (el tuyo y el de tus vecinos) tendr&aacute;n una fecha de
        vencimiento que ser&aacute; visible para cada usuario y podr&aacute;n ser utilizados hasta esas fechas
        inclusive.</li></p>
    <p><li>Los usuarios podr&aacute;n usar sus cupones para abonar total o parcialmente sus expensas. Adem&aacute;s, los cupones podr&aacute;n contar con un monto m&iacute;nimo para poder ser aplicados.</li></p>
    <p><li>El monto del cup&oacute;n ser&aacute; aplicado sobre el total del importe a abonar. De existir una diferencia entre el monto del descuento y el monto de la expensa a abonar, se
        informar&aacute; al usuario el importe de dicha diferencia y podr&aacute; abonarse con el mismo medio de pago
        seleccionado.</li></p>
    <p><li>El cup&oacute;n s&oacute;lo podr&aacute; utilizarse una vez.</li></p>
    <p><li>Si el monto de la expensa no supera el m&iacute;nimo para ser aplicado, &eacute;ste no podr&aacute; ser
        utilizado y se perder&aacute; al finalizar el plazo de la campa&ntilde;a vigente.</li></p>
        <p><li>El usuario podr&aacute; compartir su cup&oacute;n con todos sus vecinos y podr&aacute; ser utilizado 1
        &uacute;nica vez.</li></p>
        <p><li>Expensas Pagas podr&aacute; inhabilitar cualquier cup&oacute;n que haya sido acreditado por error.</li></p>
        <p><li>En caso de que se detecte fraude, Expensas Pagas se reserva el derecho a impedir el uso de los cupones
        generados.</li></p>
        <p><li>Cualquier acci&oacute;n que mediante el uso de dispositivos, software u otros instrumentos, tienda a interferir
        y/o intentar vulnerar la seguridad del sistema y de la operatoria del portal, ser&aacute; considerada
        il&iacute;cita y har&aacute; pasible a su responsable de las acciones legales pertinentes.</li></p>
        <p><li>Expensas Pagas se reserva el derecho de pronunciarse oportunamente sobre aquellas situaciones o circunstancias
        que no est&eacute;n expresamente previstas en estos t&eacute;rminos y condiciones.</li></p>
        <p><li>Los usuarios se comprometen a no hacer un uso desautorizado o indebido del programa de referidos, en perjuicio o
        fraude de Expensas Pagas.</li></p>
        <p><li>Expensas Pagas se reserva la posibilidad de actualizar los presentes t&eacute;rminos y condiciones, para la cual
        publicar&aacute; en su portal la versi&oacute;n m&aacute;s reciente de &eacute;stos. Los cupones generados se
        regir&aacute;n siempre por la versi&oacute;n m&aacute;s reciente de los mismos.</li></p>
        <p><li>Sin perjuicio de lo anterior, todo lo dispuesto en cuanto a los t&eacute;rminos y condiciones permanecer&aacute;
        vigente entre Expensas Pagas y los usuarios en
        <a href="https://www.expensaspagas.online/referidos/terminos-y-condiciones/"
           target="_blank"><span>www.expensaspagas.online/referidos/terminos-y-condiciones/</span></a>
    </li></p>
</ol>
`;
