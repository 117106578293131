import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})

export class ConfirmationModalComponent {
  @Input() open: boolean;

  @Input() title: string="ATENCIÓN";
  @Input() description: string="";
  @Input() question: string="¿Estás seguro de querer continuar?";
  @Input() closeButtom: string="Cancelar";
  @Input() acceptButtom: string="Continuar";

  @Input() acceptButtomEnabled: boolean=true;
  @Input() closeButtomEnabled: boolean=true;

  
  @Output() closeEvent = new EventEmitter();
  @Output() confirmEvent = new EventEmitter();

  public cancel(): void {
    this.closeEvent.emit();
  }

  public confirm(): void {
    this.confirmEvent.emit();
  }

}
