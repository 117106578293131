export class WizardStep {
  stepNumber: number;
  name: string;
  state: string;
  path: string;
  constructor(stepNumber, name, path) {
    this.stepNumber = stepNumber;
    this.name = name;
    this.state = null;
    this.path = path;
  }
}

export class WizardParam {
  transactionId?: string;
  code?: string;
  name?: string;
  email?: string;
  view?: string;
}

export class NewStep {
  step: number;
  transaction: string;
}
