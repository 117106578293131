<div class="c-modal c-modal--text"
     id="terms-and-conditions"
     [ngClass]="{'c-modal--is-visible': open}">
  <div class="c-modal__content">
    <a (click)="close()"
       class="c-modal__close"></a>

    <header class="header__block">
      <h2>¡Compartí tu cupón!</h2>
    </header>

    <div class="c-modal__wrapper">
      <div class="share-modal">
        <div class="targets">

          <button class="button button--secondary"
                  (click)="shareCouponToWhatsapp()">
            <img src="../../../assets/icons/whatsapp-icon.svg">
            <span>WhatsApp</span>
          </button>

          <button class="button button--secondary"
                  (click)="shareCouponToFacebook()">
            <img src="../../../assets/icons/facebook-messenger-icon.svg">
            <span>Messenger</span>
          </button>

          <!-- <button class="button button--secondary" (click)="shareCouponToInstagram()">
            <img src="../../../assets/icons/instagram-icon.svg">
            <span>Instagram</span>
          </button> -->

          <button class="button button--secondary"
                  (click)="shareCouponToEmail()">
            <img src="../../../assets/icons/email-icon.svg">
            <span>Email</span>
          </button>
        </div>
        <div class="link">
          <button class="copy-link button button--secondary"
                  (click)="copyMessageToClipboard()">Copiar mensaje</button>
        </div>
      </div>
    </div>
  </div>
</div>