<div *ngIf="payment.coupon" class="c-accordion">
    <div class="c-accordion__item">
        <div class="c-accordion__header_coupon">
            <div class="c-accordion__imgtext">
                <img src="../assets/icons/discount-coupon.svg">
                <small>Descuento EXP</small>
            </div>
            <small class="c-accordion__action ">- {{payment.coupon.amount | currency:
                'ARS':'symbol-narrow':'1.2-2':'es-AR' }}</small>
        </div>
    </div>
</div>