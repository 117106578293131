import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationModalComponent } from './information-modal.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    InformationModalComponent
  ],
  exports: [
    InformationModalComponent
  ]
})

export class InformationModalModule { }
