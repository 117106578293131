import {Injectable} from '@angular/core';
import {HttpService} from './http/service';
import {MAX_AMOUNT} from '../constants/globals.constant';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private httpService: HttpService
  ) {
    this.loadMaxAmount();
  }

  public loadMaxAmount() {
    this.httpService.get('max-amount-payzen')
      .subscribe(
        maxAmountPayzen => MAX_AMOUNT.value = maxAmountPayzen,
        error => console.log('No se pudo obtener el monto maximo de transaccion para Payzen. ', error.message)
      );
  }

  setDecimalFormat(amount: any) {
    if (amount % 1 !== 0) {
      return (Math.round(amount * 100) / 100).toFixed(2).replace('.', ',');
    } else {
      return parseInt(amount, 10).toString();
    }
  }

}
