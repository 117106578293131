import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appInputSelectAll]'
})

export class InputSelectAllDirective {

  @HostListener('focus', ['$event'])

  onfocus(event) {
    event.target.select();
  }
}

