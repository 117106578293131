import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WizzardRenderService {

  public render: boolean = true;
  public $render: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.render);

  constructor() {
  }

  renderWizzard(): Observable<boolean> {
    return this.$render.asObservable();
  }

  hideWizzard(): void {
    this.render = false;
    this.$render.next(this.render);
  }

  showWizzard(): void {
    this.render = true;
    this.$render.next(this.render);
  }

  toogleWizzard(): void {
    this.render = !this.render;
    this.$render.next(this.render);
  }

}
