<div class="c-modal" id="eliminar-codigo" [ngClass]="{'c-modal--is-visible': open}">
  <div class="c-modal__content">
    <a (click)="cancel()" class="c-modal__close"></a>
    <header class="header__block">
      <h2>{{title}}</h2>
    </header>

    <div class="c-modal__wrapper">
      <p>{{description}}</p>
      <p [innerHTML]="question"></p>
      <div class="c-modal__actions">
        <button *ngIf="closeButtomEnabled" class="button button--secondary" (click)="cancel()">{{closeButtom}}</button>
        <button *ngIf="acceptButtomEnabled" class="button button--primary" (click)="confirm()">{{acceptButtom}}</button>
      </div>
    </div>
  </div>
</div>
