export class ModalConfig {
  code: string;
  title: string;
  subtitle: string;
  img: string;
  body: string;
  footer: string;
  constructor() {
    this.code = '';
    this.title = '';
    this.subtitle = '';
    this.img = '';
    this.body = '';
    this.footer = '';
  }
}
