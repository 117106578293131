import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message-handler',
  templateUrl: './message-handler.component.html',
  styleUrls: ['./message-handler.component.scss']
})

export class MessageHandlerComponent implements  OnChanges {
  @Input() messageType: string;
  @Input() message: string;
  @Input() options = {alignCenter: false};
  @Input() showMessage: boolean;
  classType: string;

  constructor() { }

  ngOnChanges() {
    this.setClassType();
  }

  setClassType() {
    if ( ['warning', 'error', 'notice', 'success'].includes(this.messageType) ) {
      this.classType = 'message--' + this.messageType;
    }
  }
}
