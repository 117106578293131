import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule,  } from '@angular/forms';
import { WizardModule } from '../wizard/wizard.module';
import { HeaderModule } from '../header/header.module';
import { InformationModalModule } from './components/information-modal/information-modal.module';
import { ConfirmationModalModule } from './components/confirmation-modal/confirmation-modal.module';
import { LoaderModule } from './components/loader/loader.module';
import { MessageHandlerModule } from './components/message-handler/message-handler.module';
import { ExpirationDateDirective } from './directives/expiration-date/expiration-date.directive';
import { InputFilterCharactersDirective } from './directives/input-filter-characters/input-filter-characters.directive';
import { InputFilterOnlyLettersDirective } from './directives/input-filter-characters/input-filter-only-leters.directive';
import { InputSelectAllDirective } from './directives/input-select-all/input-select-all';
import { DecimalCommaPipe } from './pipes/currency-formatter/currency-formatter.pipe';
import { BlockScreenComponent } from './components/block-screen/block-screen.component';
import { SecureSiteLabelComponent } from './components/secure-site-label/secure-site-label.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { DataPrivacyPolicyComponent } from './components/data-privacy-policy/data-privacy-policy.component';
import { PaymentTransactionSummaryComponent } from './components/payment-transaction-summary/payment-transaction-summary.component';
import { DicountCouponSummaryComponent } from './components/dicount-coupon-summary/dicount-coupon-summary.component';
import { ProcessInfoMessageModule } from './components/process-info-message/process-info-message.module';
import { DatePickerModule } from './components/date-picker/date-picker.module';
import { SharedMessageModalComponent } from './components/shared-message-modal/shared-message-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InformationModalModule,
    FormsModule,
    HeaderModule,
    WizardModule,
    LoaderModule,
    ProcessInfoMessageModule,
    InformationModalModule,
    ConfirmationModalModule,
    MessageHandlerModule,
  ],
  declarations: [
    ExpirationDateDirective,
    InputFilterCharactersDirective,
    InputFilterOnlyLettersDirective,
    InputSelectAllDirective,
    DecimalCommaPipe,
    BlockScreenComponent,
    SecureSiteLabelComponent,
    TermsAndConditionsComponent,
    DataPrivacyPolicyComponent,
    PaymentTransactionSummaryComponent,
    DicountCouponSummaryComponent,
    SharedMessageModalComponent
  ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        InformationModalModule,
        DatePickerModule,
        FormsModule,
        HeaderModule,
        WizardModule,
        LoaderModule,
        ProcessInfoMessageModule,
        InformationModalModule,
        ConfirmationModalModule,
        MessageHandlerModule,
        ExpirationDateDirective,
        InputFilterCharactersDirective,
        InputFilterOnlyLettersDirective,
        InputSelectAllDirective,
        DecimalCommaPipe,
        BlockScreenComponent,
        SecureSiteLabelComponent,
        TermsAndConditionsComponent,
        DataPrivacyPolicyComponent,
        PaymentTransactionSummaryComponent,
        SharedMessageModalComponent
    ]
})

export class SharedModule { }
