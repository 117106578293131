<header class="header__main">
  <div class="header__top">
    <div class="wrapper">
      <a href=""
         class="header__logo">
        <img src="../assets/img/header__logo.svg">
      </a>
      <h1 [innerHtml]="title"></h1>
    </div>
  </div>
  <app-wizard *ngIf="showStepWizzard"></app-wizard>
</header>