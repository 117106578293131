import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { WizardService } from "./wizard/wizard.service";
import { LoaderService } from "./shared/services/loader/loader.service";
import { AppComponent } from "./app.component";
import { HttpService } from "./shared/services/http/service";
import { PaymentTransactionService } from "./shared/services/payment-transaction/payment-transaction.service";
import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es-AR";
import { AvailableService } from "./shared/guards/available-service";
import { UnavailableService } from "./shared/guards/unavailable-service";
import { ApplicationInsightsService } from "./shared/services/application-insights/application-insights.service";
import { ApplicationInsightsErrorHandlerService } from "./shared/services/application-insights-error-handler/application-insights-error-handler.service";
import { AppService } from "./shared/services/app.service";
import { ConfigInterceptor } from "./config.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { PayzenService } from "./shared/services/payzen/payzen.service";
import { PdfViewerModule } from "ng2-pdf-viewer";
registerLocaleData(localeEs, "es-AR");

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 50000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
  ],
  declarations: [AppComponent],
  providers: [
    HttpService,
    LoaderService,
    WizardService,
    PaymentTransactionService,
    PayzenService,
    AvailableService,
    UnavailableService,
    ApplicationInsightsService,
    { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandlerService },
    AppService,
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ConfigInterceptor,
        multi: true,
      },
    ],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
