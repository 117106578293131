import { Component, Input, OnInit } from '@angular/core';
import { Transaction } from '../../models/payment-transaction.model';
import { PaymentComponent } from '../payment.component';

@Component({
  selector: 'app-payment-transaction-summary',
  templateUrl: './payment-transaction-summary.component.html',
  styleUrls: ['./payment-transaction-summary.component.scss']
})
export class PaymentTransactionSummaryComponent extends PaymentComponent implements OnInit {

  @Input() transaction:Transaction = new Transaction();
  constructor() {
    super();
  }

  ngOnInit() {
  }

}
