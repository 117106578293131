import { Component, Input, OnInit } from '@angular/core';
import { Payment } from '../../models/payment-transaction.model';

@Component({
  selector: 'app-dicount-coupon-summary',
  templateUrl: './dicount-coupon-summary.component.html',
  styleUrls: ['./dicount-coupon-summary.component.scss']
})
export class DicountCouponSummaryComponent implements OnInit {

  @Input() payment:Payment = new Payment();
  constructor() {
  }

  ngOnInit() {
  }

}
