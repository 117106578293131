import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotificationService } from "../../services/toast/toast.service";

@Component({
  selector: "app-shared-message-modal",
  templateUrl: "./shared-message-modal.component.html",
  styleUrls: ["./shared-message-modal.component.scss"],
})
export class SharedMessageModalComponent implements OnInit {
  @Input() open: boolean;
  @Input() message: string;
  @Output() closeEvent = new EventEmitter();

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {}

  public close(): void {
    this.closeEvent.emit();
  }

  public shareCouponToFacebook() {
    this.copyMessageToClipboard();
    setTimeout(() => {
      window.open("https://www.messenger.com/new");
    }, 1500);
  }

  public shareCouponToWhatsapp() {
    window.open(
      `https://web.whatsapp.com/send?text=${this.message}`
    );
  }

  public shareCouponToInstagram() {}

  public shareCouponToEmail() {
    let url = "mailto:?body=" + this.message;
    window.open(url, "sharer");
  }

  public copyMessageToClipboard() {
    navigator.clipboard.writeText(this.message);
    this.notificationService.showInfo("Mensaje Copiado", "");
  }
}
