import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { environment } from '../environments/environment';
  
  @Injectable()
  export class ConfigInterceptor implements HttpInterceptor {
  
    constructor() {}
  
    intercept(request: HttpRequest<any>, next: HttpHandler): any {
      
      request = request.clone({
        headers: request.headers.set('PortalId', environment.portalId.toString()),
      });
  
      return next.handle(request);
    }
  }