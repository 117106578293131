import { Component, OnInit } from '@angular/core';
import {SERVICE_AVAILABLE} from '../shared/constants/globals.constant';
import {ActivatedRoute} from '@angular/router';
import {WizzardRenderService} from '../shared/services/wizzard-render/wizzard-render.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  public showStepWizzard = SERVICE_AVAILABLE;
  public title: string;
  public portalId:number;

  constructor(private wizzardRenderService: WizzardRenderService) {
  }


  ngOnInit() {
    this.portalId = environment.portalId;
    this.title = environment.title;
    this.wizzardRenderService.$render.subscribe(
      render => {
        this.showStepWizzard = render;
      }
    );
  }

}
