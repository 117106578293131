import {Observable, Subject,forkJoin} from 'rxjs';
import { HttpService } from '../http/service';
import { ExpensesDetail } from '../../../shared/models/expenses-detail.model';
import { RegisteredPayment } from '../../models/registered-payment.model';
import { Payment } from '../../models/payment-transaction.model';
import { map } from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class InitPaymentService {
  public registeredPaymentCache: RegisteredPayment[];
  public registeredPaymentsSubject = new Subject<Map<string,RegisteredPayment[]>>();
  public registeredPaymentsPartial = this.registeredPaymentsSubject.asObservable();
  public existPartialPayment: boolean;

  constructor(private httpService: HttpService) {}

  public getBuildingExpensesByCode(code: string): Observable<ExpensesDetail[]> {
    return this.httpService.getWithQueryParam('building-expenses', code).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public getRegisteredPaymentsByCode(payments: Payment[]): Observable<Map<string,RegisteredPayment[]>> {
    let result: Map<string,RegisteredPayment[]> = new Map();
    
    let cpes = this.getCPE(payments);
    let request = cpes.map(cpe => 
      {return this.httpService.getWithQueryParam('registered-payments', cpe)}
    );

    return forkJoin(request).pipe( map((results: any[]) => {
      results.forEach((currentValue, index) => {
        result.set(cpes[index],currentValue);
      })

      return result;
    }));
    
  }
  
  private getCPE(payments : Payment[]) {
    let unique = [...new Set(payments.map(pm => pm.buildingExpense.electronicPaymentCode))];
    return unique;
  }

  public sendLastParcialPayment(registeredPayment: Map<string,RegisteredPayment[]>): void {
    this.registeredPaymentsSubject.next(registeredPayment);
  }

  public isCollapsed() {
    return JSON.parse(sessionStorage.getItem('collapseCache'));
  }

  public collapse(value) {
    sessionStorage.setItem('collapseCache', JSON.stringify(value));
  }
  
  public setExistPartialPayments(value) {
    this.existPartialPayment = value;
  }

  public existPartialPayments() {
    return this.existPartialPayment;
  }
}
