<header class="header header--info">
    <h2>Resumen</h2>
</header>
<div class="footer__main">
    <div *ngFor="let payment of transaction.payments; index as i" >
        <div class="c-accordion">
            <div class="c-accordion__item">
                <div class="c-accordion__header">
                    <span class="c-accordion__id">
                        {{payment.buildingExpense.electronicPaymentCode}}
                        <small [innerHTML]="expenseDescriptionHeader(payment.buildingExpense)"></small>
                    </span>
                    <small>{{payment.amount | currency: 'ARS':'symbol-narrow':'1.2-2':'es-AR' }}</small>
                </div>
            </div>
        </div>
        <app-dicount-coupon-summary [payment]="payment"></app-dicount-coupon-summary>
    </div>
    <p class="total-payment">
        <span>Total: <em id="spec-total-acumulado">{{transaction.amount | currency:
                'ARS':'symbol-narrow':'1.2-2':'es-AR' }}</em></span>
    </p>
</div>