<div class="connection-lost">
  <p class="wrapper">Se ha perdido la conexión. Por favor, revisa que estés conectado a una red de Internet.</p>
</div>
<app-block-screen></app-block-screen>
<app-header></app-header>

<main class="wrapper" role="main">
  <app-loader></app-loader>
  <router-outlet></router-outlet>
</main>
