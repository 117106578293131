import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalComma'
})

export class DecimalCommaPipe implements PipeTransform {

  transform(value: number, symbol?: boolean): string {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };

    if (symbol) {
      options['style'] = 'currency';
      options['currency'] = 'ARS';
    }

    return new Intl.NumberFormat('es-AR', options).format(value);
  }

}
