import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessInfoMessageComponent } from './process-info-message.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ProcessInfoMessageComponent
  ],
  exports: [
    ProcessInfoMessageComponent
  ]
})

export class ProcessInfoMessageModule { }
