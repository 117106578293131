import {Component, OnInit} from '@angular/core';
import {BlockScreenService} from '../../services/block-screen/block-screen.service';

@Component({
  selector: 'app-block-screen',
  templateUrl: './block-screen.component.html',
  styleUrls: ['./block-screen.component.scss']
})
export class BlockScreenComponent implements OnInit {

  blockScreen = false;

  constructor(private blockScreenService: BlockScreenService) {
  }

  ngOnInit() {
    this.blockScreenService.blockScreen.subscribe(
      block => this.blockScreen = block
    );
  }

}
